.c-footer {
  width: 100%;
  min-height: 160px;
  border-top: 1px solid #ddd;
  background: #eef3f6;
  background: var(--theme-footer-background-color);
}
.c-footer__section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.c-footer__info {
  padding: 32px;
  display: flex;
  justify-content: flex-start;
}
.c-footer__info__section {
  margin-right: 32px;
}
.c-footer__info__section p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-footer__info__section p + p {
  margin-top: 16px;
}
.c-footer__info__section img {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}
.c-footer__info__section span {
  width: calc(100% - 24px - 4px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.c-footer__custom__links {
  padding: 32px;
}
.c-footer__custom__links__list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  color: #49a7cc;
  text-decoration: underline;
}
.c-footer__custom__links__list li {
  margin-left: 16px;
}
.c-footer__rule {
  padding: 16px 32px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.c-footer__rule__logo {
  color: #49a7cc;
  font-weight: bold;
  margin-bottom: 16px;
}
.c-footer__rule__logo img {
  max-height: 50px;
  margin-left: 16px;
  display: inline-block;
}
.c-footer__rule__list {
  max-width: 640px;
  margin-left: -16px;
  color: #49a7cc;
  text-decoration: underline;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}
.c-footer__rule__list li {
  margin-left: 16px;
}
.c-footer__copy {
  width: 100%;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #fff;
  background: #0094cc;
  background: var(--theme-primary-color);
}
@media screen and (max-width: 768px) {
  .c-footer__custom__links {
    padding: 8px 0;
    margin-bottom: 22px;
  }
  .c-footer__info {
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 0px;
    flex-direction: column;
  }
  .c-footer__info__section {
    margin-right: 0px;
  }
  .c-footer__info__section + .c-footer__info__section {
    margin-top: 16px;
  }
  .c-footer__rule {
    width: 100%;
    padding: 16px;
    display: flex;
    align-items: flex-start;
    flex-direction: column-reverse;
  }
  .c-footer__rule__list {
    margin-bottom: 16px;
    justify-content: flex-start;
  }
  .c-footer__rule__list li {
    margin-left: 16px;
    margin-right: 16px;
  }
  .c-footer__info__section span {
    white-space: normal;
  }
}
